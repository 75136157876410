import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Content, Bound, Col, Row } from "../components/layouts"
import PageIntro from "../components/pageIntro"
import MenuReset from "../components/MenuReset"

export const ServiceLayout = ({ content, title, contentIsMarkdown, image }) => (
  <>
    <PageIntro>
      <h2>{title}</h2>
    </PageIntro>
    <Content>
      <Row>
        <Col>
          <Row>
            <Bound>
              {typeof content === "object" && (
                <pre>{JSON.stringify(content, null, 2)}</pre>
              )}
              {contentIsMarkdown ? (
                <ReactMarkdown source={content} />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: content }} />
              )}
            </Bound>
          </Row>
        </Col>
        {image && (
          <Col>
            <img src={image} alt={title} />
          </Col>
        )}
      </Row>
    </Content>
  </>
)

export default function Service({ data }) {
  const post = data.markdownRemark
  return (
    <Layout solidHeader>
      <SEO title={post.frontmatter.title} />
      <MenuReset />

      <ServiceLayout
        title={post.frontmatter.title}
        content={post.html}
        tags={post.frontmatter.tags}
        image={post.frontmatter.image}
      />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        image
      }
    }
  }
`
